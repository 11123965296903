window.addEventListener('load', function(){
    
    let btnBack = this.document.querySelectorAll('.btn_back')
    let modalWindow = document.querySelector('.popup');
    btnBack.forEach(function(item){
        if(item){
            item.addEventListener('click',function(e){
                e.preventDefault()
               // let modal = document.querySelector('.popup')
                modalWindow.classList.remove('hide')
                modalWindow.classList.add('show')
                return false
            })
        }
    })

    function closeModal(){
        modalWindow.classList.add('hide');
        modalWindow.classList.remove('show');
        document.body.style.overflow = '';
      };

      modalWindow.addEventListener('click', (e) => {       
        let popupWrapper = document.querySelector('.popup__wrapper')      
        if (e.target === popupWrapper) {
           closeModal();}
        })

        
    let form = document.querySelector('#myform')
    form.addEventListener('submit', function(event){
        event.preventDefault()
        fetch('./mail.php', {
            method: 'POST',
            body: new FormData(form)
            }).
            then(function(response){
                closeModal()
            }).
            catch(function(error) {
            // Здесь можно обрабатывать ошибки
            console.error(error);
            });
    })
    

    

    
    
});